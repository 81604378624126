@import 'resources/css/variables';
@import 'resources/css/mixins';
@import 'resources/css/breakpoints';

.error-page {
  margin-left: 10%;
  margin-right: 10%;
  flex-direction: column-reverse;
  @include px-to-rem(50, 'padding-top');
  @include px-to-rem(1100, 'max-width');

  .left {
    width: 100%;
    @include px-to-rem(500, 'max-width');
  }

  .right {
    width: 100%;
    @include px-to-rem(500, 'max-width');
  }

  @include md {
    flex-direction: row;

    .left {
      width: 50%;
      @include px-to-rem(50, 'padding-right');
    }

    .right {
      width: 50%;
    }
  }

  @include lg {
    @include px-to-rem(130, 'padding-top');
  }

  @include xl {
    margin-left: auto;
    margin-right: auto;

    .left {
      @include px-to-rem(130, 'padding-top');
    }
  }
}
