@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tooltip-show {
  to {
    opacity: 0.9;
    top: calc(100% + 9px);
  }
}

@keyframes tooltip-show-top {
  to {
    opacity: 0.9;
    top: calc(-100% - 9px);
  }
}

@keyframes elegant-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes elegant-hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes enter-modal {
  from {
    transform: translate(100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes exit-modal {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(100%, 0);
  }
}
@keyframes flashflip {
  0% {
    opacity: 0;
    transform: rotateY(-180deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(+180deg);
  }
}
