@import 'resources/css/variables';
@import 'resources/css/mixins';
/* stylelint-disable no-duplicate-selectors */
// Default Style
.button-link {
  text-decoration: none;
}

.btn {
  @include border-radius(4);

  font-family: 'Helvetica Neue LT W01 Bold Condensed', sans-serif;
  font-weight: bold;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  @include milli-em-to-em(30);
  @include px-to-rem-4d(1, 6, 1, 6, 'padding');

  transition: all 0.15s linear;
}

// With Icon

.btn-withIcon {
  &.btn-xl .icon-component {
    @include px-to-rem-4d(0, 8, 0, 11, 'margin');
  }

  &.btn-l .icon-component {
    @include px-to-rem-4d(0, 8, 0, 0, 'margin');
  }

  &.btn-s .icon-component {
    @include px-to-rem-4d(0, 5, 0, 0, 'margin');
  }

  .icon-component {
    display: flex;
    justify-content: space-between;

    svg {
      @include px-to-rem(20, 'width');
      @include px-to-rem(20, 'height');

      transition: all 0.15s linear;
    }
  }
}

// With Loading

.btn-isLoading {
  &.btn-xl:not(.btn-withIcon) .icon-component {
    @include px-to-rem-4d(0, 11, 0, 8, 'margin');
  }

  &.btn-l:not(.btn-withIcon) .icon-component {
    @include px-to-rem-4d(0, 0, 0, 8, 'margin');
  }

  &.btn-s:not(.btn-withIcon) .icon-component {
    @include px-to-rem-4d(0, 0, 0, 5, 'margin');
  }

  .icon-component {
    display: flex;
    justify-content: space-between;

    svg {
      animation: rotation 1.2s infinite linear;
      @include px-to-rem(20, 'width');
      @include px-to-rem(20, 'height');

      stroke: $white;
    }
  }
}

// Dropdown

.icon-component.icon-dropdown {
  pointer-events: none;

  svg {
    @include px-to-rem(10, 'width');
    @include px-to-rem(10, 'height');
    @include px-to-rem(5, 'margin-left');
  }
}

// Sizes

.btn-xl {
  @include px-to-rem(145, 'width');
  @include px-to-rem(16, 'font-size');
  @include px-to-rem(38, 'height');
  @include milli-em-to-em(30, 'letter-spacing');
}

.btn-l {
  @include px-to-rem(120, 'min-width');
  @include px-to-rem(14, 'font-size');
  @include px-to-rem(35, 'height');
  @include milli-em-to-em(30, 'letter-spacing');
}

.btn-s {
  @include px-to-rem(90, 'width');
  @include px-to-rem(9, 'font-size');
  @include px-to-rem(24, 'height');
  @include milli-em-to-em(40, 'letter-spacing');
}

.btn-sq {
  @include px-to-rem(35, 'width');
  @include px-to-rem(14, 'font-size');
  @include px-to-rem(35, 'height');
  @include px-to-rem(10, 'padding-right');
  @include px-to-rem(10, 'padding-left');

  font-family: 'Helvetica Neue LT W01 Medium', sans-serif;
  white-space: nowrap;

  .icon-component svg {
    @include px-to-rem(15, 'width');
    @include px-to-rem(15, 'height');
  }
}

.no-width {
  width: auto;
  min-width: 0;
  white-space: nowrap;
  @include px-to-rem-4d(0, 12, 0, 12, 'padding');
}

.btn-right {
  float: right;
}

.btn-left {
  float: left;
}

// *********Color States***********
// disabled
.btn-disabled {
  cursor: not-allowed;
  color: $white;
  @include px-to-rem(1, 'border', solid $mid-grey);

  background-color: $mid-grey;

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }
}

.btn-disabled-inverted {
  cursor: not-allowed;
  color: $mid-grey;
  @include px-to-rem(1, 'border', solid $mid-grey);

  background-color: $white;

  .icon-component svg {
    fill: $mid-grey;
    cursor: inherit;

    g {
      fill: $mid-grey;

      rect {
        fill: $mid-grey;
      }

      path {
        fill: $mid-grey;
      }
    }
  }
}

// default
.btn-default {
  background-color: $i3d-red;
  color: $white;
  @include px-to-rem(1, 'border', solid $i3d-red);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    color: $white;
    border: none;
    background-color: $mid-grey;

    &:focus {
      background-color: $mid-grey;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $i3d-dark-red;
    @include px-to-rem(1, 'border', solid $i3d-dark-red);
  }

  &:active {
    cursor: pointer;
    background-color: $maroon;
    @include px-to-rem(1, 'border', solid $maroon);
  }
}

.btn-default-inverted {
  background-color: $white;
  color: $i3d-red;
  @include px-to-rem(1, 'border', solid $i3d-red);

  .icon-component svg {
    fill: $i3d-red;
    cursor: inherit;

    g {
      fill: $i3d-red;

      rect {
        fill: $i3d-red;
      }

      path {
        fill: $i3d-red;
      }
    }
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $white;
    color: $mid-grey;
    @include px-to-rem(1, 'border', solid $mid-grey);

    .icon-component svg {
      fill: $mid-grey;
      cursor: inherit;

      g {
        fill: $mid-grey;

        rect {
          fill: $mid-grey;
        }

        path {
          fill: $mid-grey;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
    @include px-to-rem(1, 'border', solid $i3d-dark-red);

    background-color: $i3d-dark-red;
    color: $white;

    .icon-component svg {
      fill: $white;
      cursor: inherit;

      g {
        fill: $white;

        rect {
          fill: $white;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &:active {
    background-color: $maroon;
    @include px-to-rem(1, 'border', solid $maroon);

    color: $white;
  }
}

// black
.btn-black {
  background-color: $dark-grey;
  color: $white;
  @include px-to-rem(1, 'border', solid $dark-grey);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    color: $white;
    border: none;
    background-color: $mid-grey;

    &:focus {
      background-color: $mid-grey;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $antracite;
    @include px-to-rem(1, 'border', solid $antracite);
  }

  &:active {
    cursor: pointer;
    background-color: $i3d-black;
    @include px-to-rem(1, 'border', solid $i3d-black);
  }
}

.btn-black-inverted {
  background-color: $white;
  color: $charcoal;
  @include px-to-rem(1, 'border', solid $charcoal);

  .icon-component svg {
    fill: $charcoal;
    cursor: inherit;

    g {
      fill: $charcoal;

      rect {
        fill: $charcoal;
      }

      path {
        fill: $charcoal;
      }
    }
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $white;
    color: $mid-grey;
    @include px-to-rem(1, 'border', solid $mid-grey);

    .icon-component svg {
      fill: $mid-grey;
      cursor: inherit;

      g {
        fill: $mid-grey;

        rect {
          fill: $mid-grey;
        }

        path {
          fill: $mid-grey;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
    @include px-to-rem(1, 'border', solid $antracite);

    background-color: $antracite;
    color: $white;

    .icon-component svg {
      fill: $white;
      cursor: inherit;

      g {
        fill: $white;

        rect {
          fill: $white;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &:active {
    background-color: $i3d-black;
    @include px-to-rem(1, 'border', solid $i3d-black);

    color: $white;
  }
}

// green
.btn-green {
  background-color: $green;
  color: $white;
  border: none;
  @include px-to-rem(1, 'border', solid $green);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $antracite;
    @include px-to-rem(1, 'border', solid $antracite);

    color: $white;
  }

  &:active {
    @include px-to-rem(1, 'border', solid $i3d-black);

    background-color: $i3d-black;
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $mid-grey;
    color: $white;
    @include px-to-rem(1, 'border', solid $mid-grey);
  }
}

.btn-green-inverted {
  background-color: $white;
  color: $green;
  @include px-to-rem(1, 'border', solid $green);

  .icon-component svg {
    fill: $green;
    cursor: inherit;

    g {
      fill: $green;

      rect {
        fill: $green;
      }

      path {
        fill: $green;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $antracite;
    @include px-to-rem(1, 'border', solid $antracite);

    color: $white;

    .icon-component svg {
      fill: $white;
      cursor: inherit;

      g {
        fill: $white;

        rect {
          fill: $white;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &:active {
    @include px-to-rem(1, 'border', solid $i3d-black);

    background-color: $i3d-black;
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $white;
    color: $mid-grey;
    @include px-to-rem(1, 'border', solid $mid-grey);

    .icon-component svg {
      fill: $mid-grey;
      cursor: inherit;

      g {
        fill: $mid-grey;

        rect {
          fill: $mid-grey;
        }

        path {
          fill: $mid-grey;
        }
      }
    }
  }
}

// blue
.btn-blue {
  background-color: $google-blue;
  color: $white;
  border: none;
  @include px-to-rem(1, 'border', solid $google-blue);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $antracite;
    @include px-to-rem(1, 'border', solid $antracite);

    color: $white;
  }

  &:active {
    @include px-to-rem(1, 'border', solid $i3d-black);

    background-color: $i3d-black;
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $mid-grey;
    color: $white;
    @include px-to-rem(1, 'border', solid $mid-grey);
  }
}

.btn-blue-inverted {
  background-color: $white;
  color: $google-blue;
  @include px-to-rem(1, 'border', solid $google-blue);

  .icon-component svg {
    fill: $google-blue;
    cursor: inherit;

    g {
      fill: $google-blue;

      rect {
        fill: $google-blue;
      }

      path {
        fill: $google-blue;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $antracite;
    @include px-to-rem(1, 'border', solid $antracite);

    color: $white;

    .icon-component svg {
      fill: $white;
      cursor: inherit;

      g {
        fill: $white;

        rect {
          fill: $white;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &:active {
    @include px-to-rem(1, 'border', solid $i3d-black);

    background-color: $i3d-black;
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $white;
    color: $mid-grey;
    @include px-to-rem(1, 'border', solid $mid-grey);

    .icon-component svg {
      fill: $mid-grey;
      cursor: inherit;

      g {
        fill: $mid-grey;

        rect {
          fill: $mid-grey;
        }

        path {
          fill: $mid-grey;
        }
      }
    }
  }
}

// orange
.btn-orange {
  background-color: $gold;
  color: $white;
  border: none;
  @include px-to-rem(1, 'border', solid $gold);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $antracite;
    @include px-to-rem(1, 'border', solid $antracite);

    color: $white;
  }

  &:active {
    @include px-to-rem(1, 'border', solid $i3d-black);

    background-color: $i3d-black;
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $mid-grey;
    color: $white;
    @include px-to-rem(1, 'border', solid $mid-grey);
  }
}

.btn-orange-inverted {
  background-color: $white;
  color: $gold;
  @include px-to-rem(1, 'border', solid $gold);

  .icon-component svg {
    fill: $gold;
    cursor: inherit;

    g {
      fill: $gold;

      rect {
        fill: $gold;
      }

      path {
        fill: $gold;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $antracite;
    @include px-to-rem(1, 'border', solid $antracite);

    color: $white;

    .icon-component svg {
      fill: $white;
      cursor: inherit;

      g {
        fill: $white;

        rect {
          fill: $white;
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &:active {
    @include px-to-rem(1, 'border', solid $i3d-black);

    background-color: $i3d-black;
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $white;
    color: $mid-grey;
    @include px-to-rem(1, 'border', solid $mid-grey);

    .icon-component svg {
      fill: $mid-grey;
      cursor: inherit;

      g {
        fill: $mid-grey;

        rect {
          fill: $mid-grey;
        }

        path {
          fill: $mid-grey;
        }
      }
    }
  }
}

.btn-grey-inverted {
  background-color: $white;
  color: $charcoal;
  @include px-to-rem(1, 'border', solid $charcoal);

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $charcoal;
  }

  &:active {
    background-color: $i3d-black;
    @include px-to-rem(1, 'border', solid $i3d-black);
  }
}

.btn-white-inverted {
  background-color: transparent;
  color: $white;
  @include px-to-rem(1, 'border', solid $white);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    cursor: pointer;
    background-color: $white;
    color: $i3d-black;

    .icon-component svg {
      fill: $i3d-black;
      cursor: inherit;

      g {
        fill: $i3d-black;

        rect {
          fill: $i3d-black;
        }

        path {
          fill: $i3d-black;
        }
      }
    }
  }

  &:active {
    @include px-to-rem(1, 'border', solid $broken-white);

    background-color: $broken-white;
    color: $i3d-black;
  }
}

// grey
.btn-alibaba-grey {
  background-color: $alibaba-grey;
  color: $white;
  border: none;

  @include px-to-rem(1, 'border', solid $alibaba-grey);

  .icon-component svg {
    fill: $white;
    cursor: inherit;

    g {
      fill: $white;

      rect {
        fill: $white;
      }

      path {
        fill: $white;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $alibaba-dark-grey;
    color: $white;

    @include px-to-rem(1, 'border', solid $alibaba-dark-grey);
  }

  &:active {
    background-color: $alibaba-grey;

    @include px-to-rem(1, 'border', solid $alibaba-grey);
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
    background-color: $mid-grey;
    color: $white;

    @include px-to-rem(1, 'border', solid $mid-grey);
  }
}

.btn-tertiary {
  background-color: none;
  color: $i3d-red;
  padding-left: 0;
  padding-right: 0;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-decoration-line: underline;
  text-transform: none;
  font-family: 'Helvetica Neue LT W01 Roman', sans-serif;

  .icon-component svg {
    fill: $i3d-red;
    cursor: inherit;

    g {
      fill: $i3d-red;

      rect {
        fill: $i3d-red;
      }

      path {
        fill: $i3d-red;
      }
    }
  }

  &:hover {
    cursor: pointer;
    text-decoration-line: none;
  }

  &:active {
    text-decoration-line: none;
  }

  &.btn-disabled,
  &.btn-disabled:hover {
    cursor: not-allowed;
  }
}

// Icon Styles
.btn-default .icon-component svg,
.btn-default:hover .icon-component svg,
.btn-black .icon-component svg,
.btn-black:hover > .icon-component svg,
.btn-green .icon-component svg,
.btn-green:hover > .icon-component svg,
.btn-default-inverted:hover .icon-component svg,
.btn-grey-inverted:hover .icon-component svg,
.btn-disabled .icon-component svg,
.btn-disabled:hover .icon-component svg,
.btn-blue .icon-component svg,
.btn-blue:hover .icon-component svg,
.btn-white-inverted .icon-component svg,
.btn-blue-inverted:hover .icon-component svg {
  fill: $white;
  cursor: inherit;
}

.btn-default-inverted .icon-component svg {
  fill: $i3d-red;
  cursor: inherit;
}

.btn-disabled-inverted .icon-component svg,
.btn-disabled-inverted:hover .icon-component svg {
  fill: $mid-grey;
  cursor: inherit;
}

.btn-white-inverted:hover .icon-component svg {
  fill: $i3d-black;
  cursor: inherit;
}

.btn-black-inverted .icon-component svg {
  fill: $charcoal;
  cursor: inherit;
}

.btn-black-inverted:hover .icon-component svg {
  fill: $i3d-black;
  cursor: inherit;
}

.btn-grey-inverted .icon-component svg {
  stroke: $charcoal;
  fill: $charcoal;
  cursor: inherit;
}

.btn-blue-inverted .icon-component svg {
  fill: $google-blue;
  cursor: inherit;
}
