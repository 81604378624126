@import 'resources/css/variables';
@import 'resources/css/mixins';

.tooltip-component {
  position: relative;
  @include px-to-rem(400, 'max-width');
  @include px-to-rem-4d(9, 14, 11, 14, 'padding');
  @include border-radius(4);

  color: $white;
  box-shadow: 0 0 7px rgb(35 34 35 / 32%);
  background-color: $antracite;
  @include px-to-rem(14, 'font-size');
  @include px-to-rem(16, 'line-height');

  white-space: break-spaces;

  &::after {
    position: absolute;
    content: '';
    inset: -10px;
    z-index: -1;
  }

  &.small {
    @include px-to-rem(290, 'max-width');
  }

  a {
    color: $white;

    &:hover {
      color: $dark-grey;
    }
  }

  button {
    background: transparent;
    @include px-to-rem(2, 'border', solid $white);

    &.no-border {
      border: 0;
    }

    &:hover,
    &:focus {
      background: $white;
      color: $antracite;
    }

    &:last-child {
      @include px-to-rem(10, 'margin-left');
    }
  }
}
