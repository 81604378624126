/* Colors */

$i3d-red: #d2232a;
$i3d-light-red: #f6d3d4;
$i3d-dark-red: #93272c;
$maroon: #662428;
$light-red: #c99396;
$i3d-red_10: rgba(#d2232a, 0.1);
$i3d-red_20: rgba(#d2232a, 0.17);
$i3d-red_30: rgba(#d2232a, 0.25);
$marked: #f5d2d3;
$i3d-black: #232223;
$antracite: #404040;
$charcoal: #757575;
$disabled: #7b7b7b;
$dark-grey: #757575;
$blue-grey: #7c8b9d;
$grey: #acacac;
$mid-grey: #cdcdcd;
$light-grey: #e6e6e6;
$broken-white: #f2f2f2;
$white: #fff;
$i3d-green: #dcedcc;
$amazon-orange: #fce6cc;
$i3d-blue: #2d5465;
$google-blue: #4285f4;
$google-blue_20: rgba(#4181ee, 0.2);
$tencent-blue: #88b7ff;
$google-blue-trans: #d7e6fc;
$alibaba-grey: #263e4d;
$alibaba-grey_70: rgba(#263e4d, 0.7);
$alibaba-dark-grey: #1a2f3c;
$azure-purple: #8d54ac;
$uc-aqua: #d0f5f2;
$dark-green: #407e04;
$green: #51a300;
$green_15: rgba(#51a300, 0.15);
$green_30: rgba(#51a300, 0.3);
$light-green: #b4e879;
$lighter-green: #e5f1d9;
$army-green: #9eae6a;
$yellow: #ffe600;
$gold: #ff8000;
$gold_10: rgba(#ff8000, 0.1);
$gold_20: rgba(#ff8000, 0.17);
$gold_30: rgba(#ff8000, 0.25);
$broken-gold: #fdf0da;
$medium-blue: #59b7ff;
$light-blue: #7fc7e2;
$navy-blue: #092db7;
$taupe: #7e87bc;
$pink: #e69de2;
$salmon: #fd625e;
$aqua: #1ccbbd;
