@charset 'utf-8';
@import 'resources/css/reset';
@import 'resources/css/variables';
@import 'resources/css/mixins';
@import 'resources/css/grid';
@import 'resources/css/helpers';
@import 'resources/css/scrollbar';

/* Fonts should go here */

@font-face {
  font-family: 'Helvetica Neue LT W01 Bold';
  src: url('./resources/fonts/HelveticaNeueLTW01-75Bold.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT W01 Bold Condensed';
  src: url('./resources/fonts/HelveticaNeueLTW01-77BoldCn.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT W01 Roman';
  src: url('./resources/fonts/HelveticaNeueLTW01-55Roman.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT W01 Medium';
  src: url('./resources/fonts/HelveticaNeueLTW01-65Medium.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Neue LT W01 Light';
  src: url('./resources/fonts/HelveticaNeueLTW01-45Light.otf');
  font-display: swap;
}

html {
  box-sizing: border-box;
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: $antracite;
  margin: 0;
  padding: 0;
  font-family:
    'Helvetica Neue LT W01 Roman',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[hidden] {
  display: none;
}

button {
  appearance: auto;
  writing-mode: horizontal-tb !important;
  font-size: 100%;
  text-align: center;
  align-items: flex-start;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-rendering: auto;
  @include px-to-rem-4d(1, 6, 1, 6, 'padding');
}

input {
  appearance: auto;
  background-color: $white;
}

button::-moz-focus-inner {
  border: 0;
}

/* Main container */

#main-content {
  background: $broken-white;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 42px);
  overflow: hidden auto;

  &.is-admin {
    .container {
      @include px-to-rem(84, 'margin-top');
    }

    .side-menu-component .menu {
      @include px-to-rem(100, 'padding-top');
    }
  }

  &.menu-extended {
    margin-left: 0;
    @include lg {
      @include px-to-rem(220, 'margin-left');
    }
  }
}

/* Headings */

h1 {
  @include px-to-rem(32, 'font-size');

  font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
  color: $i3d-black;
  @include px-to-rem(40, 'line-height');
}

h2 {
  @include px-to-rem(24, 'font-size');

  font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
  color: $i3d-black;
  @include px-to-rem(30, 'line-height');
}

h3 {
  @include px-to-rem(18, 'font-size');

  font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
  color: $i3d-black;
  @include px-to-rem(24, 'line-height');
}

h4 {
  @include px-to-rem(16, 'font-size');

  font-family: 'Helvetica Neue LT W01 Roman', sans-serif;
  color: $i3d-black;
  @include px-to-rem(22, 'line-height');

  b {
    font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
  }
}

h5 {
  @include px-to-rem(14, 'font-size');

  font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
  color: $i3d-black;
  @include px-to-rem(20, 'line-height');
}

h6 {
  @include px-to-rem(10, 'font-size');

  font-family: 'Helvetica Neue LT W01 Medium', sans-serif;
  color: $i3d-black;
  @include px-to-rem(14, 'line-height');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  &.dark-red {
    color: $i3d-dark-red;
  }

  &.mid-grey {
    color: $mid-grey;
  }

  &.charcoal {
    color: $charcoal;
  }
}

/* Paragraphs */
p {
  @include px-to-rem(14, 'font-size');

  font-family: 'Helvetica Neue LT W01 Roman', sans-serif;
  margin: 0;
  @include px-to-rem(18, 'line-height');
  @include milli-em-to-em(6);

  &.small {
    @include px-to-rem(10, 'font-size');
    @include px-to-rem(12, 'line-height');
  }

  &.label,
  span.label {
    font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
    color: $i3d-red;

    &.black {
      color: $i3d-black;
    }
  }

  &.bold,
  span.bold,
  b {
    font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
  }

  &.text-center {
    text-align: center;
  }

  &.charcoal {
    color: $charcoal;
  }

  &.grey {
    color: $grey;
  }

  &.dark-grey {
    color: $dark-grey;
  }

  &.dark-red {
    color: $i3d-dark-red;
  }

  &.red {
    color: $i3d-red;
  }
}

strong {
  font-family: 'Helvetica Neue LT W01 Bold', sans-serif;
}

/* Lists */

li {
  list-style-type: none;
  position: relative;

  &.disc {
    list-style-type: disc;
  }
}

/* Links */

a {
  cursor: pointer;
  text-decoration: underline;
  color: $i3d-red;

  &:hover {
    color: $i3d-dark-red;
  }
}

.a {
  @include px-to-rem(14, 'font-size');

  font-family: 'Helvetica Neue LT W01 Roman', sans-serif;
  color: $i3d-red;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease-in;

  &.small {
    @include px-to-rem(10, 'font-size');
    @include px-to-rem(12, 'line-height');
  }

  &.grey {
    color: $dark-grey;

    &:hover {
      color: $antracite;
    }
  }

  &.charcoal {
    color: $charcoal;

    &:hover {
      color: $i3d-black;
    }
  }

  &:hover {
    color: $i3d-dark-red;
  }
}

/* Inline form item */

.inline-form-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  &.align-end {
    justify-content: flex-end;
  }

  &.align-left {
    justify-content: flex-start;
  }

  &.align-center {
    justify-content: center;
  }

  &.center {
    align-items: center;
  }

  &.top {
    align-items: flex-start;
  }

  &.end {
    align-items: flex-end;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &.column {
    flex-flow: column;
  }

  &.small-gap {
    @include px-to-rem(10, 'gap');
  }

  &.medium-gap {
    @include px-to-rem(20, 'gap');
  }

  &.large-gap {
    @include px-to-rem(42, 'gap');
  }

  .flex-grow-1 {
    flex-grow: 1;
  }
}

.f-l {
  float: left;
}

.f-r {
  float: right;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.no-word-break {
  white-space: nowrap;
}

.inline-flex {
  display: inline-flex;
}

/* EXTRAS */

.relative {
  position: relative;
}

.pos-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.well {
  width: 100%;
  @include border-radius(4);

  background: $white;
  @include px-to-rem(12, 'padding');

  &.no-padding-left {
    @include px-to-rem(0, 'padding-left');
  }

  &.no-padding-right {
    @include px-to-rem(0, 'padding-right');
  }

  &.no-padding {
    @include px-to-rem(0, 'padding');
  }

  &.big-pad {
    @include px-to-rem-4d(24, 24, 32, 24, 'padding');
  }

  &.success {
    background: $green_15;
  }

  &.error {
    color: $i3d-red;
    background: $i3d-light-red;
  }

  &.gold {
    background: $gold_20;
  }

  &.blue {
    background: $google-blue_20;
  }

  &.with-icon {
    .icon-component {
      float: left;

      svg {
        fill: $i3d-red;
      }
    }

    p {
      @include px-to-rem(30, 'margin-left');
    }
  }

  .data-table-component {
    background: transparent;

    .data-table {
      min-width: 0;

      thead th {
        @include px-to-rem(1, 'border-top', solid $charcoal);

        vertical-align: middle;
        background: transparent;

        &::before {
          background: transparent;
        }
      }
    }
  }
}

.recharts-responsive-container {
  .recharts-label {
    @include px-to-rem(14, 'font-size');
  }

  .recharts-cartesian-axis-tick-value {
    @include px-to-rem(12, 'font-size');
  }
}

/* Cookiebot */
/* stylelint-disable-next-line selector-id-pattern */
#CybotCookiebotDialogBodyContentTitle {
  font-size: 12pt !important;
}

/* stylelint-disable-next-line selector-id-pattern */
#CybotCookiebotDialogBodyContentText {
  @include px-to-rem(-28, 'margin-bottom');
}

/* stylelint-disable-next-line selector-id-pattern */
a#CybotCookiebotDialogPoweredbyCybot {
  display: none;
}
