@import 'resources/css/variables';
@import 'resources/css/mixins';

.snackbar-component {
  position: fixed;
  z-index: 9999;
  width: 841px;
  @include px-to-rem(841, 'min-width');
  @include px-to-rem(841, 'max-width');

  display: flex;
  align-items: center;
  flex-flow: column;
  box-sizing: border-box;
  padding: 0.25rem;
  color: $white;
  pointer-events: none;

  &.top {
    top: 2.6rem;
  }

  &.bottom {
    bottom: 0;
  }

  &.center {
    left: 50%;
    transform: translate(-50%, 0%);
  }

  &.right {
    right: 0.8em;
  }

  &.left {
    left: 0.8em;
  }

  &.bottom.center {
    .toast-component {
      animation: enter-bottom 500ms ease;
    }
  }
}
