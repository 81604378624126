@import 'resources/css/variables';
@import 'resources/css/mixins';
@import 'resources/css/animations';

.icon-component {
  position: relative;
  outline: none;
  line-height: 0;

  .popper-arrow {
    &::before {
      background: $antracite !important;
    }
  }

  &.disabled {
    svg,
    img {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &.stroke {
    stroke: $dark-grey;
  }

  &.active {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      background: $i3d-red;
      @include px-to-rem(8, 'height');
      @include px-to-rem(8, 'width');
      @include px-to-rem(-1, 'top');
      @include px-to-rem(-2, 'right');
      @include border-radius(5);
    }
  }

  &.small {
    svg {
      @include px-to-rem(15, 'height');
    }
  }

  &.loading {
    pointer-events: none;

    svg {
      animation: rotation 1.2s infinite linear;
      @include px-to-rem(24, 'width');
      @include px-to-rem(24, 'height');

      stroke: $grey;
      fill: $grey;
    }
  }

  &.clickable:not(.disabled) {
    svg {
      cursor: pointer;
    }
  }

  svg {
    fill: $dark-grey;

    path,
    circle,
    rect,
    tspan {
      pointer-events: none;
    }
  }
}
