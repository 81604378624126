@import 'resources/css/variables';
@import 'resources/css/mixins';

.loading-page {
  position: absolute;

  /* NOTE: This value should be fixed to when the sidemenu is opened of closed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loading-icon svg {
    animation: rotation 1.2s infinite linear;
    @include px-to-rem(200, 'width');
    @include px-to-rem(200, 'height');

    stroke: $grey;
    fill: $grey;
  }

  .loading-label {
    text-align: center;
  }
}
